import { PlayIcon } from "@heroicons/react/solid"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useRef, useState } from "react"

const ImageVideo = ({ video, image, alt, smallIcon, className }) => {
  const [videoActice, setIsActive] = useState(false)

  const videoTag = useRef(null)
  const imageCover = getImage(image)

  const handleVideo = () => {
    setIsActive(true)
    videoTag.current.play()
  }

  return (
    <div className={`pb-0 mx-auto rounded-lg ${className ? className : ""}`}>
      <div
        className={`relative cursor-pointer shadow rounded-lg w-full ${
          videoActice ? "hidden" : "block"
        }`}
        onKeyPress={handleVideo}
        onClick={handleVideo}
      >
        <div
          className={`absolute top-0 left-0 z-10 rounded-lg flex w-full h-full transition-colors duration-300 hover:bg-gray-900 hover:opacity-80 ${
            smallIcon
              ? "items-end justify-end pb-3 pr-3"
              : "items-center justify-center"
          }`}
        ></div>
        <GatsbyImage
          image={imageCover}
          alt={alt}
          className="align-top rounded-lg"
        />
      </div>
      <video
        controls
        ref={videoTag}
        className={`rounded-lg ${videoActice ? "block" : "hidden"}`}
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  )
}

export default ImageVideo
