import { PhoneIcon, PlayIcon, PauseIcon } from "@heroicons/react/solid"
import { graphql, useStaticQuery } from "gatsby"
import React, { useRef, useState } from "react"
import video from "../../media/seo-agentur-stuttgart-intro.mp4"
import videoExplainer from "../../media/seo-agentur-stuttgart-erklaerung.mp4"
import Button from "../common/Button"
import Container from "../common/Container"
import FeatureBox from "../common/FeatureBox"
import GridTwo from "../common/GridTwo"
import ImageVideo from "../common/ImageVideo"
import H1 from "../typography/H1"
import H2 from "../typography/H2"
import P from "../typography/P"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SvgSpotify from "../common/SvgSpotify"

const IndexHeader = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contact {
            mail
            phone
            calendly
          }
        }
      }
      videoCover: file(
        relativePath: { eq: "seo-agentur-stuttgart-intro_cover.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      explainerCover: file(relativePath: { eq: "video-explainer-cover.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      focusLogo: file(relativePath: { eq: "focus-logo.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const [audioPlaying, setAudioPlaying] = useState(false)

  const audioTag = useRef(null)

  const handleAudio = () => {
    if (audioTag.current.paused) {
      audioTag.current.play()
      setAudioPlaying(true)
    } else {
      audioTag.current.pause()
      setAudioPlaying(false)
    }
    audioTag.current.onended = function () {
      setAudioPlaying(false)
    }
  }

  return (
    <Container noPadding className="my-4 md:my-16">
      <GridTwo>
        <div className="relative">
          <ImageVideo
            video={videoExplainer}
            image={data.explainerCover}
            alt="Seo Agentur Stuttgart"
            className="mb-3"
          />
          <Button
            to="/anfragen/"
            text="DER KOSTENLOSE SEO-CHECK"
            className="w-full"
          />
        </div>
        <div className="flex flex-col items-center justify-center text-center">
          <H1 display className="md:mb-4">
            <span className="text-brand-pink-dark">Seo Agentur Stuttgart</span>
          </H1>
          <H2 display className="mb-4 md:mb-12">
            <span className="text-brand-turquoise-dark">
              Mehr Kunden durch Google Seite 1 Ranking
            </span>
          </H2>
          <P className="font-bold text-xl">
            Königstraße 80 - 70173 Stuttgart
            <br />
            Büro <a href="tel:++4971123735736">0711 23735736</a>
            <br />
            Fax 0711 23735110
          </P>
        </div>
        <div>
          <P className="mb-3 text-lg">
            Die <strong>SEO Agentur Stuttgart</strong> hat die Lösung dafür, wie
            Du auch ohne Werbeanzeigen mehr Kundenanfragen bekommst. Über 90
            Prozent aller Personen, die eine Dienstleistung im Internet suchen,
            schauen sich nur die Ergebnisse auf der ersten Seite von Google an.
            Wie Du mit unserer SEO-Suchmaschinenoptimierung in Stuttgart &
            Umgebung auf dieser begehrten 1. Seite erscheinst, erklären wir Dir
            gerne anhand unserem kostenlosen SEO-CHECK. Du wirst erstaunt sein
            über das ungenutzte Potenzial Deiner Webseite und darüber, wie sie
            in kürzester Zeit auf Seite 1 bei Google landet, nachdem wir an den
            richtigen Stellschrauben gedreht haben. Unser Fokus ist
            serviceorientiert, wir sorgen dafür, dass unsere Kunden glücklich &
            erfolgreich sind und uns empfehlen – deshalb garantieren wir unsere
            Leistungen.
          </P>
          <div>
            <FeatureBox
              svg={<PhoneIcon className="w-8 h-8" />}
              href={`tel:${data.site.siteMetadata.contact.phone}`}
              text={
                <a
                  href={`tel:${data.site.siteMetadata.contact.phone}`}
                  className="hover:underline"
                >
                  {data.site.siteMetadata.contact.phone}
                </a>
              }
              subText={
                <>
                  <a
                    href={`mailto:${data.site.siteMetadata.contact.mail}`}
                    className="hover:underline"
                  >
                    {data.site.siteMetadata.contact.mail}
                  </a>
                  <br />
                  <div className="flex flex-col mt-2 w-full space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 items-start">
                    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-2 gap-3 mt-2 w-full">
                      <Button
                        href="https://calendly.com/oliver-e-bahm/seo-premium-agentur"
                        target="_blank"
                        rel="noopener noreferrer"
                        text="Terminauswahl"
                        className=""
                        noShadow
                        turquoise
                      />
                      {typeof window !== "undefined" &&
                        typeof window.Audio !== "undefined" && (
                          <>
                            <Button
                              onClick={() => handleAudio()}
                              text={
                                audioPlaying ? (
                                  <>
                                    <PauseIcon className="w-6 h-6 mr-2" />
                                    Antenne1 spielt...
                                  </>
                                ) : (
                                  <>
                                    <PlayIcon className="w-6 h-6 mr-2" />
                                    Hitradio Antenne1
                                  </>
                                )
                              }
                              noShadow
                              pink
                            />
                            <audio className="opacity-0" ref={audioTag}>
                              <source src="./hitradio.mpeg" type="audio/mpeg" />
                            </audio>
                          </>
                        )}

                      <Button
                        href="https://www.businesstalk-kudamm.com/podcasts/btk-podcast-folge-1056-2/"
                        target="_blank"
                        rel="noopener noreferrer"
                        text={
                          <>
                            <SvgSpotify className="w-6 h-6 mr-2" />
                            Podcast
                          </>
                        }
                        noShadow
                      />
                      <Button
                        href="https://www.youtube.com/watch?v=YdOnIyQoHx4"
                        target="_blank"
                        rel="noopener noreferrer"
                        text="Testimonial"
                        noShadow
                        turquoise
                      />
                    </div>

                    <a
                      href="https://unternehmen.focus.de/backlinks-kaufen.html"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <GatsbyImage
                        image={getImage(data.focusLogo)}
                        alt="SEO Agentur Stuttgart Focus Logo"
                        style={{ maxWidth: "200px" }}
                      />
                    </a>
                  </div>
                </>
              }
            />
          </div>
        </div>
        <div className="relative">
          <ImageVideo
            video={video}
            image={data.videoCover}
            alt="Seo Agentur Stuttgart Video"
            className="mb-3"
          />
          <Button
            to="/anfragen/"
            text="DER KOSTENLOSE SEO-CHECK"
            className="w-full"
          />
        </div>
      </GridTwo>
    </Container>
  )
}

export default IndexHeader
